import React from 'react';
import {
  MDBFooter,
} from 'mdb-react-ui-kit';
import jeet from './dao.png' 









export default function App() {
  return (
    <MDBFooter  className='text-center text-lg-left footer'>
      <br/>   <br/>
      <img src={jeet} style={{width:"200px"}}/>
      
      <br/> <br/>
     

      <div className='text-center p-3 bgf' style={{ fontWeight:"800" , color:"#fff" }} >
        &copy; {new Date().getFullYear()} Copyright DAO
      </div>
    </MDBFooter>
  );
}