import React, { useState, useRef, useEffect } from 'react';
import daoouterside from './outer_side.png';

function PFP() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [error, setError] = useState(null);
  const [borderRadius, setBorderRadius] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const img = new Image();

    reader.onloadend = () => {
      img.src = reader.result;
      img.onload = () => {
        // Resize image to 200x200
        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, 200, 200);
        const resizedImageURL = canvas.toDataURL();
        setUploadedImage(resizedImageURL);
        setError(null);
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const daoImage = new Image();
    daoImage.src = daoouterside;
    daoImage.onload = () => {
      canvas.width = daoImage.width;
      canvas.height = daoImage.height;
      ctx.drawImage(daoImage, 0, 0);

      if (uploadedImage) {
        const uploadedImg = new Image();
        uploadedImg.src = uploadedImage;
        uploadedImg.onload = () => {
          const width = window.innerWidth <= 480 ? 170 : 200;
          const height = window.innerWidth <= 480 ? 170 : 200;

          const adjustedPosition = {
            x: window.innerWidth <= 480 ? (daoImage.width - width) / 2 : position.x,
            y: window.innerWidth <= 480 ? (daoImage.height - height) / 2 : position.y,
          };

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(adjustedPosition.x + borderRadius, adjustedPosition.y);
          ctx.arcTo(adjustedPosition.x + width, adjustedPosition.y, adjustedPosition.x + width, adjustedPosition.y + height, borderRadius);
          ctx.arcTo(adjustedPosition.x + width, adjustedPosition.y + height, adjustedPosition.x, adjustedPosition.y + height, borderRadius);
          ctx.arcTo(adjustedPosition.x, adjustedPosition.y + height, adjustedPosition.x, adjustedPosition.y, borderRadius);
          ctx.arcTo(adjustedPosition.x, adjustedPosition.y, adjustedPosition.x + width, adjustedPosition.y, borderRadius);
          ctx.closePath();
          ctx.clip();

          ctx.drawImage(uploadedImg, adjustedPosition.x, adjustedPosition.y, width, height);
          ctx.restore();

          const dataURL = canvas.toDataURL();
          const a = document.createElement('a');
          a.href = dataURL;
          a.download = 'composed_image.png';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      }
    };
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    const imgBounds = imgRef.current.getBoundingClientRect();
    const offsetX = e.clientX !== undefined ? e.clientX - imgBounds.left : e.touches[0].clientX - imgBounds.left;
    const offsetY = e.clientY !== undefined ? e.clientY - imgBounds.top : e.touches[0].clientY - imgBounds.top;
    setMouseOffset({ x: offsetX, y: offsetY });
    setDragging(true);
  };

  const handleDragMove = (e) => {
    if (dragging) {
      const containerBounds = containerRef.current.getBoundingClientRect();
      const newX = (e.clientX !== undefined ? e.clientX : e.touches[0].clientX) - containerBounds.left - mouseOffset.x;
      const newY = (e.clientY !== undefined ? e.clientY : e.touches[0].clientY) - containerBounds.top - mouseOffset.y;
      setPosition({
        x: Math.max(0, Math.min(containerBounds.width - (window.innerWidth <= 480 ? 100 : 200), newX)),
        y: Math.max(0, Math.min(containerBounds.height - (window.innerWidth <= 480 ? 100 : 200), newY)),
      });
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);
    } else {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    };
  }, [dragging]);

  return (
    <div className='bgp'>
      <div className="containers">
        <h2>Upload Your Profile Picture</h2>
        <input type="file" accept="image/*" onChange={handleImageUpload} />

        {error && <p className="error">{error}</p>}
        {uploadedImage && (
          <div className="controls">
            <label>
              Border Radius:
              <input
                type="range"
                min="0"
                max="100"
                value={borderRadius}
                onChange={(e) => setBorderRadius(Number(e.target.value))}
              />
            </label>
          </div>
        )}
        {uploadedImage && (
          <div className="preview">
            <h3>Composed Image Preview</h3>
            <div className="dao-frame" ref={containerRef}>
              <img src={daoouterside} alt="dao frame" className="dao-img" />
              <img
                src={uploadedImage}
                alt="uploaded image"
                className="uploaded-img"
                ref={imgRef}
                style={{
                  borderRadius: `${borderRadius}px`,
                  left: window.innerWidth <= 480 ? '50%' : `${position.x}px`,
                  top: window.innerWidth <= 480 ? '50%' : `${position.y}px`,
                  transform: window.innerWidth <= 480 ? 'translate(-50%, -50%)' : 'none',
                  position: 'absolute',
                  width: window.innerWidth <= 480 ? '100px' : '200px',
                  height: window.innerWidth <= 480 ? '100px' : '200px',
                }}
                onMouseDown={handleDragStart}
                onTouchStart={handleDragStart}
              />
            </div>
            <button className="download-btn" onClick={handleDownload}>Download Composed Image</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PFP;
