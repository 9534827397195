import React from 'react';
import { Grid } from '@mui/material';
import dog from './a2.MP4';
import dog2 from './a1.MP4';
function Gib() {
  return (
    <div className='navbartop'><br/> <br/> <br/> <br/> 
        <h1 className='DAOl rota1'>   its not a cto its a dao    <br/><br/></h1>
      <div className='container'>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall rota3'>
            <div style={{ position: 'relative', paddingTop: '56.25%', width: '100%', border: '3px solid black' }}>
              <video
                src={dog2}
                autoPlay
                loop
                muted  // Add this line to mute the video
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: '3px solid black' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <br/><br/><br/>
      <br/><br/><br/>
      <div className='container'>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall rota1'>
            <div style={{ position: 'relative', paddingTop: '56.25%', width: '100%', border: '3px solid black' }}>
              <video
                src={dog}
                autoPlay
                loop
                muted  // Add this line to mute the video
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: '3px solid black' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <br/><br/><br/>
    </div>
  );
}

export default Gib;
