import React from 'react'
import { IoBackspaceOutline } from "react-icons/io5";
import { Grid } from '@mui/material'
import a1 from './Aust/1.jpg'
import a2 from './Aust/2.jpg'
import a3 from './Aust/3.jpg'
import a4 from './Aust/4.jpg'
import a5 from './Aust/5.jpg'
import a6 from './Aust/6.jpg'
import a7 from './Aust/7.jpg'
function You_are_not_an_autistic() {
  return (
  <div className='you_are_not_an_autistic'>
  <div className=' container'>


<h1 className='head_you_are_not_an_autistic'>
    
<a href='./'><IoBackspaceOutline style={{color:"#000"}}/></a>

</h1>
<br/>
<Grid Grid container spacing={4}>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a1} className='no_aus_meme'/>
</Grid>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a4} className='no_aus_meme'/>
</Grid>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a5} className='no_aus_meme'/>
</Grid>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a7} className='no_aus_meme'/>
</Grid>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a2} className='no_aus_meme'/>
</Grid>
<Grid item md={6} xs={12} lg={6} sm={12} className='centeritall'>

<img src={a6} className='no_aus_meme'/>
</Grid>
</Grid>


 </div>

  </div> 
 
  )
}

export default You_are_not_an_autistic