import React from 'react'
import { Grid } from '@mui/material'
import Link from '@mui/material/Link';
import l1 from './twitter.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});
function Navbar() {
  return (
    <div className='navbartop'>


<Grid container spacing={2}
>

<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
<div className='centeritall rota1' >
               <div data-aos="fade-right">  
               <Link href='./DAO-PFP' className='font_top_nav '> 
             
               PFP MAKER
              </Link>
                 </div>
                
           
               </div>

</Grid>

</Grid>
      <br/>
       <div className='container'>
        <Grid container spacing={2}>
       <Grid item md={3} xs={6} lg={3} sm={6} className='centeritall'> 
               <div className='centeritall rota1' >
               <div data-aos="fade-right">  
               <Link href='https://x.com/daoonsolcoin' className='font_top_nav '> 
             
             Twitter
              </Link>
                 </div>
                
           
               </div>
               </Grid>
              
               <Grid item md={3} xs={6} lg={3} sm={6} className='centeritall'> 
               <div className='centeritall rota3' >
               <div data-aos="fade-right">  
               <Link href='https://t.me/AustismPortal' className='font_top_nav'> 
             
             Telegram
              </Link>
                 </div>
                
           
               </div>
               </Grid>
              
             
               <Grid item md={3} xs={6} lg={3} sm={6} className='centeritall'> 
               <div className='centeritall rota1' >
               <div data-aos="fade-right">  
               <Link href='https://dexscreener.com/solana/cddwdjxyetuce97o491qwd5trjhkbxcspup8mcibgyde' className='font_top_nav'> 
             
            Chart
              </Link>
                 </div>
                
           
               </div>
               </Grid>
              
           

               <Grid item md={3} xs={6} lg={3} sm={6} className='centeritall'> 
               <div className='centeritall rota3' >
               <div data-aos="fade-right">  
               <Link href='https://raydium.io/swap/?outputMint=2FxuYGVZRoUXg51zffnYxymqerFAuSEQabAsLzTFpump&inputMint=sol' className='font_top_nav'> 
             
        Buy
              </Link>
                 </div>
                
           
               </div>
               </Grid>
              
             
                             </Grid></div>
                             <br/>
      
      
      </div>
  )
}

export default Navbar