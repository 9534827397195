import React, { useState } from "react";
import musicFile from "./dao.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Desktop from "./Desktop";
import run from "./run.jpg";
import runs from "./bst.png";
import MEME from "./Desktopthree";
import Dex from "./Dex";
import Marquee from "react-fast-marquee";
import { Grid } from '@mui/material'
import Video from './Gib'
import Footer from './Footer'
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

import Logo from './u.png'




const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };

  const { width, height } = useWindowSize();

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="you_are_not_an_autistic">
         
            <Confetti width={width} height={height} recycle={false} />
            <Marquee>
<img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>
            <Desktop />
            <Marquee>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>

<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
  </Marquee>
            <br/><br/>
            <Dex />    <br/>  <Marquee>
<img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>
            <br/><br/> 
            <MEME />  <br/>
            <br/><br/>       <Marquee>
<img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>     <Video />
            <br/><br/> 
          
            <Footer />


            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >
<Confetti width={width} height={height} recycle={false} />
<div className='navbartops'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>



</Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

       <img src={Logo} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a ><h1 className='enterlnd' >u autistic?</h1></a>    
               </Grid>


</Grid>



<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a onClick={() => {
                      handleYesClick();
                      setShowModal(false);
                    }}><h1 className='enterlnd' >Yes</h1></a>    
               </Grid>
               <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a href="./you_are_not_an_autistic"><h1 className='enterlnd' >No</h1></a>    
               </Grid>

</Grid>

 </Grid>


 
        <br/> <br/>
    </div>
         
        </Modal>
      </>
          </div>
        )}

        {smallScreen && (
          <div className="you_are_not_an_autistic">
            <Confetti width={width} height={height} recycle={false} />
            <Marquee>
<img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>
            <Desktop />
            <Marquee>
<img src={run} style={{width:"100px"}}/>

<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>

<img src={run} style={{width:"100px"}}/>


<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
<img src={run} style={{width:"100px"}}/>
  </Marquee>
            <br/><br/>
            <Dex />    <br/>  <Marquee>
<img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>
            <br/><br/> 
            <MEME />  <br/>
            <br/><br/>       <Marquee>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>
<img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/><img src={runs} style={{width:"40px"}}/>

  </Marquee>     <Video />
            <br/><br/> 
          
            <Footer />
            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >
<Confetti width={width} height={height} recycle={false} />
<div className='navbartops'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>



</Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

       <img src={Logo} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a ><h1 className='enterlnd' >u autistic?</h1></a>    
               </Grid>


</Grid>



<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a onClick={() => {
                      handleYesClick();
                      setShowModal(false);
                    }}><h1 className='enterlnd' >Yes</h1></a>    
               </Grid>
               <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a href="./you_are_not_an_autistic"><h1 className='enterlnd' >No</h1></a>    
               </Grid>

</Grid>

 </Grid>


 
        <br/> <br/>
    </div>
         
        </Modal>
      </>
          </div>
        )}
      </div>

    
    </div>
  );
};

export default Home;
