import React from 'react'
import Navbar from './Navbar'
import { Grid } from '@mui/material'

import Logo1 from './dao.png'

import AOS from "aos";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});

function Desktop() {
  const { width, height } = useWindowSize();
  return (
    <div className='container'>
        <Confetti width={width} height={height} recycle={false} />   <Confetti width={width} height={height} recycle={false} />   <Confetti  width={width} height={height} recycle={false} />
       <Navbar/>
       <br/> <br/> 
       <Grid container spacing={2}>
       <Grid item md={6} xs={12} lg={6} sm={12} > 
               <div className='centeritall ' ><br/> <br/> <br/><br/> <br/> <br/>
               <img src={Logo1} style={{width:"80%"}}/>
               </div>
              
             
             </Grid>

             <Grid item md={6} xs={12} lg={6} sm={12} > <br/> <br/>
               <div className='centeritall ' >
             <h1 className='DAO rota1'> $DAO</h1>
               </div>
               <div className='centeritall ' >
             <h1 className='DAOs rota3'> on sol</h1>
               </div>
             
             </Grid>



           

       </Grid>
      
       <br/><br/>
      <br/><br/>

     </div>
  )
}

export default Desktop