import React from 'react'
import { Grid } from '@mui/material'
import cto from './cto.jpg'
import w1 from './da/1.webp'
import w2 from './da/2.webp'
import w3 from './da/3.webp'
import w4 from './da/4.webp'
import w5 from './da/5.webp'
import w6 from './da/6.webp'
import w7 from './da/7.webp'
import w8 from './da/8.webp'
import w9 from './da/9.webp'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1600
});

function Dex() {
  return (
    <div className='bgsts'> 

    <div className='container'>
    <br/>
<br/>
<div className='bgca rota2'>
<p>CA : 2FxuYGVZRoUXg51zffnYxymqerFAuSEQabAsLzTFpump</p>

</div><br/><br/>


    </div>

    <div className='container'>
<Grid container spacing={2}>
<Grid item md={12} lg={12} xs={12} sm={12}> 
<p className='dahf rota1' >in doatoshi we trust</p>
<br/>
<div className='centeritall'><img src={cto} className='ctos rota3'/>
</div><br/><br/><br/>
<br/><br/><br/><br/><br/><br/>
</Grid>


<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w1} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w2} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w3} style={{width:"100%"}}/>
   
</Grid>


<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w4} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w5} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w6} style={{width:"100%"}}/>
   
</Grid>

<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w7} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w8} style={{width:"100%"}}/>
   
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='centeritall rota3'> 
<img src={w9} style={{width:"100%"}}/>
   
</Grid>
</Grid>


<br/><br/>



<br/><br/>


    </div>
    
   </div>
 
  )
}

export default Dex