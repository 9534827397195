import React from 'react'
import { Grid } from '@mui/material'


import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});

function Desktopthree() {
  return (
    <div className='container'>
          <Grid container spacing={2}>



          <h1 className='DAOl rota3'>Degenerative Autistic Organization Manifesto  <br/></h1>
         
          <p className='dah rota3'>
            
            In $DAO We Trust:
            
            
             We're here for the memes, not the means. Hodl for life or until our degenerative brains forget our wallets' passwords. <br/>
          </p>
        
          <p className='dah rota3'>
            
            Solana and Insanity:
            
             Running on Solana because our degenerative minds can’t handle Ethereum gas fees. If it’s cheap, it’s $DAO!<br/></p>



          <p className='dah rota3' >
            
            Autistic Authenticity:
            
            
             We analyze charts like a hamster on caffeine, every dip is a buying opportunity (or a panic sell, who knows?).<br/></p>


          <p className='dah rota3'>
            
            Degenerate Decisions:
            
            
             Our investment strategy? Flip a coin. Heads: YOLO in. Tails: YOLO out. Risk management? Never heard of her.<br/></p>


          <p className='dah rota3'>
            
            Community Chaos:
            
            
             Join us for the most nonsensical discussions and the wildest ride in crypto. If it makes sense, it’s not $DAO.<br/></p>


<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>




</Grid>
  </Grid>

  
<br/>
    </div>
  )
}

export default Desktopthree